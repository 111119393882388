import React, { useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import { useSearch } from "../context/search";
import { useNavigate } from "react-router-dom";
import "../styles/serch.css";
import { useCart } from "../context/cart";
import { MagnifyingGlass } from "react-loader-spinner";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Search = () => {
  const [values, setValues] = useSearch();
  const navigate = useNavigate();
  const [cart, setCart] = useCart();

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title={"Search results"}>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          display="grid"
          alignItems="center"
          width="100%"
          justifyContent="center"
          textAlign="center"
        >
          <Button colorScheme="gray">Search Resuts</Button>

          <Text>
            {values?.results.length < 1 ? (
              <>
                <Text textAlign="center" display="grid">
                  Search Your Courses
                </Text>
                <br></br>
                <MagnifyingGlass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="magnifying-glass-loading"
                  wrapperStyle={{}}
                  wrapperClass="magnifying-glass-wrapper"
                  glassColor="#c0efff"
                  color="#e15b64"
                />
              </>
            ) : null}
          </Text>
        </Box>
        <Box marginTop={2}>
          <Container maxW="container.xl" p={4}>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(4, 1fr)",
              }}
              gap={6}
            >
              {values?.results.map((p) => (
                <GridItem
                  key={p._id}
                  onClick={() => navigate(`/product/${p.slug}/`)}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  {p.image.length > 0 && (
                    <Image
                      src={p.image}
                      alt="uploading...."
                      borderRadius="lg"
                      width="100%"
                      height="180px"
                      objectFit="cover"
                      padding={1}
                    />
                  )}
                  <VStack p={2} align="stretch">
                    <Heading size="md" fontFamily="poppins">
                      {p.name.slice(0, 20)}
                    </Heading>
                    <VStack align="left" display="inline">
                      <Text fontFamily="poppins">
                        {p.description.slice(0, 80)}...
                      </Text>
                      <Box display="flex">
                        <Text color="green" fontSize="large">
                          Offers: {p.offers}%
                        </Text>
                        {/* {[1, 2, 3, 4, 5].map((starValue) => (
                          <p key={starValue} className="star">
                            <FontAwesomeIcon
                              className=""
                              icon={faStar}
                              color={
                                p.ratting >= starValue ? "green" : "#767980"
                              }
                            />
                          </p>
                        ))} */}
                      </Box>
                    </VStack>
                    <Flex justifyContent="space-between">
                      <Button
                        width="100%"
                        colorScheme="green"
                        fontSize="small"
                        onClick={() => {
                          const existingProduct = cart.find(
                            (item) => item._id === p._id
                          );
                          if (existingProduct) {
                            navigate("/cart");
                          } else {
                            setCart([...cart, { ...p, quantity: 1 }]);
                            localStorage.setItem(
                              "cart",
                              JSON.stringify([...cart, { ...p, quantity: 1 }])
                            );
                            navigate("/cart");
                          }
                        }}
                      >
                        Buy Now {"  "}₹ {p.price}
                      </Button>
                    </Flex>
                  </VStack>
                </GridItem>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>
    </Layout>
  );
};

export default Search;

import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import * as mod from "./../../url";
import FooterArea from "../../components/Layout/FooterArea";
import TopHeader from "../../components/Layout/TopHeader";
import { Box, Button, FormLabel, Input, useDisclosure } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const ForgotPasssword = () => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const toast = useToast();
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    phone: "",
    otp: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // form function
  const handleSubmit = async (e) => {
    onClose();
    e.preventDefault();
    try {
      const res = await axios.post(
        `${mod.api_url}/api/v1/auth/forgot-password`,
        formData
      );

      if (res && res.data.success) {
        toast({
          title: "Password Forget Sucessfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        navigate("/login");
        setFormData("");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast({
        title: "Password Forget Error.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };
  const generateOTP = async () => {
    if (formData.phone.length !== 10) {
      return toast.error("Invalid phone number");
    }

    try {
      const res = await axios.post(`${mod.api_url}/api/v1/auth/generate-otp`, {
        phone: formData.phone,
        email: formData.email,
        mode: "reset password",
      });
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error while sending OTP");
    }
  };

  const doubleClick = () => {
    if (!formData.phone || !formData.email || !formData.newPassword) {
      toast({
        title: "Fill the all Input",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    generateOTP();
    onOpen();
  };

  return (
    <>
      <TopHeader />
      <div className="login_page">
        <div className="wrapper">
          <div className="title_head">Reset Password</div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <FormLabel>Enter Phone</FormLabel>
                <Input
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  id="exampleInputEmail1"
                  placeholder="Resigterd Mobile"
                  required
                />
              </div>
              <div className="col-md-6">
                <FormLabel>Enter Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="exampleInputEmail1"
                  placeholder="Resigterd Email"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <FormLabel>Enter Password</FormLabel>
                <Input
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  id="exampleInputPassword1"
                  placeholder="New Password"
                  required
                />
              </div>
              <div className="col-md-6">
                <FormLabel>Gernate OTP</FormLabel>
                <Button colorScheme="blue" width="100%" onClick={doubleClick}>
                  OTP
                </Button>
              </div>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Verify OTP</ModalHeader>
                <ModalBody>
                  <Input
                    type="text"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    label="OTP"
                    variant="outlined"
                    placeholder=" OTP"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    width="48%"
                    colorScheme="green"
                    onClick={handleSubmit}
                    m={1}
                  >
                    Submit
                  </Button>
                  <Button width="48%" colorScheme="red" onClick={onClose}>
                    Close Modal
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <hr />

            <div className="signup-link">
              <Link to="/Login">Login</Link>
            </div>
          </form>
        </div>
      </div>
      <FooterArea />
    </>
  );
};

export default ForgotPasssword;

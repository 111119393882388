import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const OurMission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/about-us.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <h1>About Us</h1>
        <p>
          Welcome to ValuedProPvt, where we elevate your learning experience
          with our comprehensive Learning Management System (LMS) platform. At
          ValuedProPvt, we are committed to providing cutting-edge courses,
          expert guidance, and a seamless user experience to foster your
          professional growth.
        </p>
        <h3>Our Mission</h3>
        <p>
          Our mission is to empower individuals and organizations with the
          knowledge and skills they need to thrive in today's dynamic world. We
          believe that continuous learning and development are key to unlocking
          your full potential and achieving success in your career.
        </p>
        <h3>What We Offer</h3>
        <h5>Comprehensive Courses</h5>
        <p>
          Our LMS platform features a wide range of courses designed to meet the
          diverse needs of our learners. Whether you're looking to enhance your
          technical skills, develop leadership capabilities, or explore new
          areas of expertise, we have the right course for you.
        </p>
        <h3>Expert Guidance</h3>
        <p>
          Learn from industry experts and thought leaders who bring real-world
          experience and insights to our courses. Our instructors are dedicated
          to providing you with the guidance and support you need to succeed in
          your learning journey.
        </p>
        <h3>Seamless User Experience</h3>
        <p>
          Our user-friendly platform ensures a seamless and engaging learning
          experience. With intuitive navigation, interactive content, and
          personalized learning paths, you can focus on what matters most – your
          professional growth.
        </p>
        <h2>Why Choose ValuedProPvt?</h2>
        <h5>Quality Education</h5>
        <p>
          We are committed to delivering high-quality education that meets the
          highest standards of excellence. Our courses are designed to be
          relevant, practical, and aligned with industry trends.
        </p>
        <h3>Flexibility and Convenience</h3>
        <p>
          Learn at your own pace and on your own schedule. Our LMS platform is
          accessible from anywhere, at any time, making it easy for you to
          integrate learning into your busy life.
        </p>
        <h3>Continuous Improvement</h3>
        <p>
          We continuously update our courses and platform to ensure you have
          access to the latest knowledge and technologies. Your feedback is
          important to us, and we strive to make your learning experience better
          every day.
        </p>
        <h3>Join Us Today</h3>
        <p>
          At ValuedProPvt, we are passionate about helping you achieve your
          professional goals. Join our community of learners and take the next
          step in your career with our comprehensive LMS platform. Elevate your
          learning experience with ValuedProPvt and unlock your potential for
          success.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default OurMission;

import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import { useCart } from "../context/cart";
import * as mod from "./../../src/url.js";
import {
  FaBookReader,
  FaBusinessTime,
  FaFilePdf,
  FaStar,
} from "react-icons/fa";
import { FcLike } from "react-icons/fc";
import { FcDislike } from "react-icons/fc";
import { TbCertificate, TbCertificateOff } from "react-icons/tb";
import Modal from "react-modal";
import { Hourglass } from "react-loader-spinner";
import { useAuth } from "../context/auth.js";
import { toast } from "react-toastify";
import { Button, Heading, Image, Text } from "@chakra-ui/react";
import { BsHourglass } from "react-icons/bs";
import { GiLaptop } from "react-icons/gi";
import { PiArticleMediumLight } from "react-icons/pi";

const colorOptions = ["#4285F4", "#EA4335", "#FBBC05", "#34A853"];

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [cart, setCart] = useCart([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auth, setAuth] = useAuth();
  const [likeStatus, setLikeStatus] = useState([]);
  useEffect(() => {
    // Initialize likeStatus with false for each review
    setLikeStatus(Array(product?.reviews?.length).fill(false));
  }, [product.reviews]);
  const handleLikeReview = async (reviewIndex) => {
    try {
      const userId = auth.user._id;
      // console.log(userId, "userId");
      const updatedLikeStatus = [...likeStatus];
      const review = product.reviews[reviewIndex];

      if (!review.likes.includes(userId)) {
        review.likes.push(userId);

        await axios.put(
          `/api/v1/product/${product._id}/reviews/${reviewIndex}/like`,
          {
            userId: userId,
          }
        );

        updatedLikeStatus[reviewIndex] = true;
        setLikeStatus(updatedLikeStatus);
      } else {
        toast.error("User has already liked this review");
      }
    } catch (error) {
      console.error("Error liking review:", error);
    }
  };

  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);

  //getProduct
  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      setRating(data?.product?.rating || 0);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };

  const getSimilarProduct = async (pid, cid) => {
    // window.location.reload();
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
      setSelectedImage(null);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(colorProducts,'ppppppppppp')

  const buyNow = () => {
    const existingProduct = cart.find((item) => item._id === product._id);
    if (existingProduct) {
      navigate("/cart");
    } else {
      // If the product doesn't exist, add it to the cart and redirect to the checkout page
      setCart([
        ...cart,
        { ...product, quantity: 1, size: selectedSize, colors: selectedColor },
      ]);
      localStorage.setItem(
        "cart",
        JSON.stringify([
          ...cart,
          {
            ...product,
            quantity: 1,
            size: selectedSize,
            colors: selectedColor,
          },
        ])
      );

      navigate("/cart");
    }
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const reviewsToShowInitially = 5;
  const [visibleReviews, setVisibleReviews] = useState(reviewsToShowInitially);

  const handleLoadMore = () => {
    setVisibleReviews(
      (prevVisibleReviews) => prevVisibleReviews + reviewsToShowInitially
    );
  };
  const result = (() => {
    let ratingCounts = {};

    product?.reviews?.forEach((review) => {
      const { rating } = review;
      ratingCounts[rating] = (ratingCounts[rating] || 0) + 1;
    });

    return { ratingCounts };
  })();

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Text fontSize="x-large" textAlign="center">
        Course Details
      </Text>
      <hr
        style={{
          background:
            "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
          height: "5px",
          border: "none",
        }}
      />{" "}
      <div className="row product-details justify-content-center single-page  ">
        <div className="col-md-6 mt-1 top_data">
          <div className="w-[45%] flex-1 flex flex-col p-6">
            <Image
              src={product.image}
              alt="banner Image"
              width="100%"
              height="auto"
            />
          </div>
          <Button
            width="100%"
            marginBottom="3px"
            colorScheme="green"
            fontSize={14}
            className="but"
            onClick={buyNow}
            marginTop={2}
          >
            Buy Now{" "}
          </Button>
        </div>

        {/* details part*/}
        <div className="col-md-6  product-details-info">
          <div
            className="scroller-bar"
            style={{
              height: "500px",
              width: "100%",
              overflowY: "auto",
              scrollbarWidth: "thin", // Firefox
              WebkitOverflowScrolling: "touch", // iOS
              marginLeft: "8px",
              padding: "10px",
            }}
          >
            <style>
              {`
                  ::-webkit-scrollbar {
                    width: 5px;
                    height: 0px;
                  }
                  ::-webkit-scrollbar-thumb {
                    background-color: #ccc; // Set your desired scrollbar color
                  }
                `}
            </style>
            <Text fontSize="x-large" fontFamily="poppins">
              {product.name}
            </Text>
            <h6>
              {product.offers > 0 ? (
                <Text
                  size="sm"
                  fontFamily="poppins"
                  color="green"
                  marginBottom="3px"
                >
                  {(() => {
                    const prices = (product.price * product.offers) / 100;
                    const finalPrice = product.price - prices;
                    const disc = product.price - finalPrice;
                    return (
                      <>
                        You save Extra Rs.
                        {disc.toFixed(2)} off
                      </>
                    );
                  })()}
                  &nbsp; &nbsp;
                  <del>₹{product.price}</del>
                  &nbsp; &nbsp; {product.offers}% off !!!!
                </Text>
              ) : (
                <h6 style={{ color: "red" }}>
                  {(() => {
                    const prices = (product.price * product.offers) / 100;
                    const finalPrice = product.price - prices;
                    const disc = product.price - finalPrice;
                    return (
                      <>
                        <span>Rs. {finalPrice.toFixed(2)}</span>
                      </>
                    );
                  })()}
                </h6>
              )}
            </h6>
            <Text size="sm" fontFamily="poppins" marginBottom="3px">
              <strong> Category : </strong>
              {product?.course?.name}
            </Text>

            <div className="row">
              <div className="col-12">
                <h5>Description: </h5>
                <h6>
                  {/* {product.description} */}
                  {(() => {
                    const pro = product.description;
                    if (typeof pro !== "string") {
                      return <div>Invalid description</div>;
                    }
                    const wordsArray = pro.split(";");
                    return (
                      <>
                        <div>
                          {wordsArray.map((info, index) => (
                            <p key={index}>
                              {" "}
                              <span style={{ fontSize: 20 }}>.</span> &nbsp;
                              {info.trim()}
                            </p>
                          ))}
                        </div>
                      </>
                    );
                  })()}
                </h6>
                <div>{product && <div></div>}</div>
              </div>

              <h1>{product.title}</h1>
              <div className="rating-and-review">
                <h style={{ marginLeft: "5px", marginRight: "9px" }}>
                  {product?.reviews?.length}, Ratings & Reviews:
                </h>
                <div
                  className="review-total-pper"
                  style={{ display: "inline-flex", background: "green" }}
                >
                  <h6 style={{ display: "inline-flex" }}>
                    <h6
                      style={{
                        color: "white",
                        marginTop: "3px",
                        marginLeft: "2px",
                      }}
                    >
                      5
                    </h6>
                  </h6>
                  <FaStar
                    style={{
                      color: "white",
                      display: "inline-flex",
                      marginTop: "2px",
                      marginLeft: "3px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <hr
                style={{
                  background:
                    "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
                  height: "5px",
                  border: "none",
                }}
              />{" "}
              {product?.reviews?.slice(0, visibleReviews).map((r, index) => (
                <>
                  <div key={r.reviewId} style={{ color: "green" }}>
                    <div className="rating-back">
                      <h6 style={{ color: "white", display: "inline-flex" }}>
                        {r?.rating}
                      </h6>
                      <FaStar
                        style={{
                          color: "white",
                          display: "inline-flex",
                          marginLeft: "2px",
                          marginBottom: "2px",
                        }}
                      />
                    </div>
                    <p
                      style={{
                        display: "inline",
                        marginRight: "10px",
                        color: "#7b7676",
                      }}
                    >
                      {" "}
                      {r?.comment}
                    </p>
                    <img
                      // src={require(`./../img/produtImg/${r?.images[0]}`)}
                      src="/images/e2.png"
                      alt="Selected Image"
                      className="revieimgaes"
                      onClick={handleImageClick}
                    />
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={handleCloseModal}
                      contentLabel="Image Modal"
                      style={{
                        content: {
                          width: "350px",
                          height: "350px",
                          margin: "auto",
                          marginTop: "60px",
                        },
                      }}
                    >
                      <img
                        src="/images/e2.png"
                        alt="Selected Image"
                        className="revieimgaes"
                        style={{ width: "100%", height: "80%" }}
                      />
                      <Button
                        width="100%"
                        colorScheme="linkedin"
                        fontSize={14}
                        marginTop={2}
                        className="b"
                        onClick={handleCloseModal}
                      >
                        Close
                      </Button>
                    </Modal>

                    <div
                      className="like-dislike-icon"
                      // onClick={handleLikeToggle}
                    >
                      {!likeStatus[index] ? (
                        <FcLike
                          onClick={() => handleLikeReview(index)}
                          style={{
                            color: "black",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        <FcDislike
                          onClick={() => handleLikeReview(index)}
                          style={{
                            color: "black",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      )}
                      <span> {r.likes.length}</span>
                    </div>
                    <div>
                      <h6 style={{ color: "#7b7676" }}>
                        Our Customer : {r?.user?.name}
                      </h6>
                      <h6 style={{ color: "#7b7676" }}>
                        <TbCertificate /> - Certified Buyer, {r?.user?.city}
                      </h6>
                    </div>
                    <hr />
                  </div>
                </>
              ))}
            </div>
            {visibleReviews < product?.reviews?.length && (
              <Button
                width="30%"
                margin="0 auto"
                colorScheme="linkedin"
                fontSize={10}
                onClick={handleLoadMore}
              >
                {/* <p>Load More</p> */}
                <Hourglass
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{
                    display: "inline",
                    marginRight: "8px",
                  }}
                  wrapperClass=""
                  colors={["white", "white"]}
                />
              </Button>
            )}
            <div className="responsive-container">
              <div className="col-md-12 mt-1 left-side">
                <div className="row">
                  <h6 className="text-center">Total Satisfied Users</h6>

                  {[5, 4, 3, 2, 1].map((rating) => (
                    <div className="row" key={rating}>
                      <div className="col-3">
                        {rating}{" "}
                        <FaStar
                          style={{
                            color: "black",
                            display: "inline-flex",
                            marginLeft: "2px",
                            marginBottom: "2px",
                          }}
                        />
                      </div>

                      <div className="col-6">
                        <div
                          style={{
                            height: "7px",
                            backgroundColor: "#ddd",
                            borderRadius: "2px",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              height: "100%",

                              width: !result.ratingCounts[rating]
                                ? "0%"
                                : `${
                                    (result.ratingCounts[rating] /
                                      (product?.reviews?.length || 1)) *
                                    10
                                  }%`,
                              backgroundColor:
                                rating === 1
                                  ? "red"
                                  : rating === 2
                                  ? "yellow"
                                  : "green",
                              borderRadius: "2px",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-3">
                        {result.ratingCounts[rating] || 0}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          background:
            "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
          height: "5px",
          border: "none",
        }}
      />
    </Layout>
  );
};
export default ProductDetails;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useCategory from "../hooks/useCategory";
import Layout from "../components/Layout/Layout";
import "../styles/Categories.css";
import Box from "@mui/material/Box";
import { Text } from "@chakra-ui/react";
import { MdCategory } from "react-icons/md";
import axios from "axios";
import * as mod from "./../../src/url.js";

const Categories = () => {
  const [course, setCourses] = useState([]);

  const getCourses = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      setCourses(data?.course);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout title={"All Categories"}>
      <div className="container">
        <div className="row">
          <Text fontSize="24px" className="text-center">
            All Courses
          </Text>
          {course?.map((c) => (
            <div
              className="col-lg-4 col-md-12  gx-3 gy-3"
              style={{ padding: "10px" }}
              key={c._id}
            >
              <Box padding={1}>
                <Link to={`/course/${c.slug}`} className="btn cat-btn">
                  <h4>{c.name}</h4>
                </Link>
              </Box>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Categories;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import TopHeader from "./TopHeader";
import FooterArea from "./FooterArea";

const Layout = ({ children, title, description, keywords, author }) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <TopHeader />
      <main style={{ minHeight: "100%", padding: "12px" }}>
        <Toaster />

        {children}
      </main>
      <FooterArea />
    </div>
  );
};

Layout.defaultProps = {
  title: "valuedpropvt",
  description: "mern stack project",
  keywords: "mern,react,node,mongodb",
  author: "sk",
};

export default Layout;

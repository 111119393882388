import React from "react";
import "./../../styles/FooterArea.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { Text } from "@chakra-ui/react";

function FooterArea() {
  return (
    <>
      <div>
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h4 className="footer-heading">Let's Learn Hear</h4>
                <div className="footer-underline"></div>
                <p>
                  Valuedpropvt.com: Elevate your learning experience with our
                  comprehensive LMS platform, offering cutting-edge courses,
                  expert guidance, and seamless user experience for professional
                  growth.
                </p>
              </div>
              <div className="col-md-4">
                <h4 className="footer-heading">Quick Links</h4>
                <div className="footer-underline"></div>
                <div className="mb-2">
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/about-us" className="text-white">
                    About US
                  </Link>
                </div>

                <div className="mb-2">
                  <Link to="/contact" className="text-white">
                    Contact Us
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/privacy-policy" className="text-white">
                    Privacy Policy
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/terms-and-condition" className="text-white">
                    Terms and Condition
                  </Link>
                </div>
                <div className="mb-2">
                  <Link to="/Cancellations-policy" className="text-white">
                    Cancellations Policy
                  </Link>
                </div>
              </div>

              <div className="col-md-4">
                <h4 className="footer-heading">Reach Us</h4>
                <div className="footer-underline"></div>
                <div className="mb-2">
                  <p>
                    <i className="fa fa-map-marker"></i> Adress: H NO B 98
                    GROUND FLOOR, NEAR NANDA HOSPITAL CHATTARPUR EXT, Sanjay
                    Colony Bhati Mines, South West Delhi, South West Delhi,
                    Delhi, India, 110074
                  </p>
                </div>
                <div className="mb-2">
                  <a href="/" className="text-white">
                    <i className="fa fa-phone"></i> +91 7827156839
                  </a>
                </div>

                <div className="mb-2">
                  <a href="/" className="text-white">
                    <i className="fa fa-envelope"></i>info@valuedpropvt.com
                  </a>
                </div>
                <span className="text-bold">
                  <span className="social-Icons">
                    <a href="https://www.facebook.com/profile.php?id=61563402368071">
                      <FaFacebook size={16} className="icon" />
                    </a>
                    <a
                      href="https://x.com/valupro40?t=eqSZHtrAiKUi-kN5KXw9sA&s=09"
                      target="_blank"
                    >
                      <FaTwitter size={16} className="icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/valuedpro_pvt"
                      target="_blank"
                    >
                      <FaInstagram size={16} className="icon" />
                    </a>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-md-11">
                <Text textAlign="center">
                  {" "}
                  All rights reserved &copy; 2024 - valuedpropvt - LMS
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterArea;

import React, { useEffect, useState } from "react";
import "./../../styles/Topheader.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import SearchInput from "../Form/SearchInput";
import useCategory from "../../hooks/useCategory";
import { useCart } from "../../context/cart";
import { Badge } from "antd";
import { AiOutlineShopping } from "react-icons/ai";
import logo from "./../../img/logo.png";
import * as mod from "./../../url";
import axios from "axios";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import Mainmenu from "./Mainmenu";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
} from "@chakra-ui/react";

import { FcList } from "react-icons/fc";

function TopHeader() {
  const [isFixed, setIsFixed] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const categories = useCategory();
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubcategoryMenu, setShowSubcategoryMenu] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearch = (value) => {
    setSearchTerm(value);
    // ... rest of the search logic
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        bg="whiteSmoke"
        color="black"
        p={1}
        height="30px"
        marginBottom="2px"
        marginTop="2px"
        boxShadow="md"
        display="flex"
      >
        <marquee
          style={{
            fontWeight: "600",
          }}
        >
          "Empower Your Learning Journey: Transform Knowledge into Success with
          Our LMS!"
        </marquee>
      </Box>
      <div className={`top-header ${isFixed ? "fixed-header" : ""}`}>
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={onOpen}
            >
              <FcList style={{ width: "30px", height: "30px" }} />
            </button>

            <div
              className={`collapse navbar-collapse headerName ${
                showDropdown ? "" : ""
              }`}
              id="navbarTogglerDemo01"
            >
              <Link to="/" className="navbar-brand">
                <img
                  src={logo}
                  style={{ width: 150, height: 60, marginRight: "20px" }}
                />
              </Link>
              {/* category */}

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <SearchInput
                  onSearch={handleSearch}
                  value={searchTerm}
                  style={{ width: "100%" }}
                />
                {!auth?.user ? (
                  <>
                    <Button colorScheme="blue" margin={2}>
                      <Link to="/login" className="nav-lik navbarHeader">
                        Login
                      </Link>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button colorScheme="blue" margin={2}>
                      <Mainmenu />
                    </Button>
                  </>
                )}
                <li className="nav-item-cart">
                  <NavLink to="/cart" className="nav-link navbarHeader">
                    <Badge count={cart?.length} offset={[10, -5]}>
                      <AiOutlineShopping
                        style={{ fontSize: "30px", color: "#08c" }}
                      />
                    </Badge>
                  </NavLink>
                </li>
              </ul>
            </div>

            <Drawer
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader>
                  <Link to="/" className="navbar-brand">
                    <img src={logo} style={{ width: 100, height: 60 }} />
                  </Link>
                </DrawerHeader>

                <DrawerBody>
                  <Link to="/">
                    <Button colorScheme="blue" margin={2} width="100%">
                      Home
                    </Button>
                  </Link>
                  {!auth?.user ? (
                    <>
                      <Button colorScheme="blue" margin={2} width="100%">
                        <Link to="/login" className="nav-lik navbarHeader">
                          Login
                        </Link>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button colorScheme="blue" margin={2} width="100%">
                        <Mainmenu />
                      </Button>
                    </>
                  )}
                  <Link to="/courses">
                    <Button colorScheme="blue" margin={2} width="100%">
                      Courses
                    </Button>
                  </Link>
                </DrawerBody>

                <DrawerFooter>
                  <Button
                    colorScheme="red"
                    width="100%"
                    mr={3}
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>

            <div className="search_name" style={{ marginLeft: 1 }}>
              <SearchInput onSearch={handleSearch} value={searchTerm} />
              <li className="nav-item-1">
                <NavLink to="/cart" className="nav-link navbarHeader">
                  <Badge count={cart?.length} offset={[10, -5]}>
                    <AiOutlineShopping
                      style={{
                        fontSize: "30px",
                        color: "#08c",
                        marginLeft: "20px",
                      }}
                    />
                  </Badge>
                </NavLink>
              </li>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default TopHeader;

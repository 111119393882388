import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import axios from "axios";
import * as mod from "./../../url.js";
import { useAuth } from "../../context/auth";
import "./../../styles/OrdersDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import TopHeader from "../../components/Layout/TopHeader.js";
import FooterArea from "../../components/Layout/FooterArea";
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Text,
  StackDivider,
} from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";

function OrdersDetails() {
  const [orders, setOrders] = useState(null);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const params = useParams();
  const { _id: orderId } = params;

  const getOrders = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/auth/orders/${orderId}`
      );
      setOrders(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (auth?.token) getOrders();
  }, [auth?.token]);

  const downloadPDF = async (fileUrl) => {
    try {
      // Fetch the file as a Blob
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element and set its href to the Blob URL
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "course.pdf"; // The default name of the downloaded file
      link.type = "application/pdf";

      // Append the link to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("There was an error fetching the PDF:", error);
    }
  };

  return (
    <>
      <TopHeader />
      <Layout title={"Order Details"}>
        <h1 className="text-center">Course Download</h1>
        <Box textAlign="center">
          {orders?.orderItems.map((item, index) => (
            <Box
              key={index}
              // onClick={() =>
              //   navigate(`/product/${item.name.replace(/\s+/g, "-")}/`)
              // }
              style={{ color: "blue", cursor: "pointer", marginBottom: "20px" }}
            >
              <Card
                direction={{ base: "column", sm: "column" }}
                overflow="hidden"
                variant="outline"
                width="100%"
              >
                <Center>
                  <Image
                    src={item?.image}
                    objectFit="cover"
                    width="250px"
                    height="100%"
                    border="1px solid #d9d9d9"
                    padding={1}
                    margin={1}
                  />
                </Center>
                <StackDivider>
                  <CardBody>
                    <Heading size="md">{item.name.slice(0, 40)}</Heading>
                    <Text py="2">Price: {item.price}</Text>
                    <Button
                      colorScheme="green"
                      onClick={() => downloadPDF(item.pdf)}
                    >
                      Download Your Course
                    </Button>
                  </CardBody>
                </StackDivider>
              </Card>
            </Box>
          ))}
        </Box>
      </Layout>
      <FooterArea />
    </>
  );
}

export default OrdersDetails;

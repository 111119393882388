import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Checkbox, Radio } from "antd";
import { Prices, Colorpic, Discount } from "../components/Prices";
import * as mod from "./../../src/url.js";
import { useCurrency } from "../context/CurrencyChange.js";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const CategoryProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  // const { selectedCurrency } = useCurrency();
  const [products, setProducts] = useState([]);
  const [course, setCourse] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.slug) {
      getProductsByCoursesetCourse();
    }
  }, [params?.slug]);
  useEffect(() => {
    getAllCategories();
    getTotal();
    getAllProducts();
  }, []);

  useEffect(() => {
    if (page === 1) {
      return;
    }
    loadMore();
  }, [page]);

  const getProductsByCoursesetCourse = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-course/${params.slug}`
      );
      setProducts(data?.products);
      setCourse(data?.course);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-list/${page}`
      );
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data?.success) {
        setCategories(data?.course);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/product-list/${page}`
      );
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="mt-3 course">
        <h4 className="text-center">CoursesetCourse - {course?.name}</h4>
        <h6 className="text-center">{products?.length} results found</h6>
        <div className="row">
          <div className="CoursesetCourse_class">
            <Box className="col-40 swiper-container">
              <div className="d-flex flex-wrap">
                <Box marginTop={2}>
                  <Container maxW="container.xl" p={4}>
                    <Grid
                      templateColumns={{
                        base: "repeat(1, 1fr)",
                        md: "repeat(4, 1fr)",
                      }}
                      gap={6}
                    >
                      {products?.map((p) => (
                        <GridItem
                          key={p._id}
                          onClick={() => navigate(`/product/${p.slug}/`)}
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                        >
                          {p.image.length > 0 && (
                            <Image
                              src={p.image}
                              alt="uploading...."
                              borderRadius="lg"
                              width="100%"
                              height="180px"
                              objectFit="cover"
                              padding={1}
                            />
                          )}
                          <VStack p={2} align="stretch">
                            <Heading size="md" fontFamily="poppins">
                              {p.name.slice(0, 20)}
                            </Heading>
                            <VStack align="left" display="inline">
                              <Text fontFamily="poppins">
                                {p.description.slice(0, 80)}...
                              </Text>
                              <Box display="flex">
                                <Text color="green" fontSize="large">
                                  Offers: {p.offers}%
                                </Text>
                                <Text
                                  color="green"
                                  fontSize="large"
                                  marginLeft="50px"
                                >
                                  Price: ₹ {p.price}
                                </Text>
                                {/* {[1, 2, 3, 4, 5].map((starValue) => (
                                  <p key={starValue} className="star">
                                    <FontAwesomeIcon
                                      className=""
                                      icon={faStar}
                                      color={
                                        p.ratting >= starValue
                                          ? "green"
                                          : "#767980"
                                      }
                                    />
                                  </p>
                                ))} */}
                              </Box>
                            </VStack>
                            <Flex justifyContent="space-between">
                              {" "}
                              <Button
                                width="100%"
                                colorScheme="linkedin"
                                fontSize={10}
                                onClick={() => navigate(`/product/${p.slug}`)}
                              >
                                View Course
                              </Button>
                            </Flex>
                          </VStack>
                        </GridItem>
                      ))}
                    </Grid>
                  </Container>
                </Box>
              </div>
              <Box>
                <div className="m-2 p-3">
                  {products && products.length < total && (
                    <Button
                      width="100%"
                      colorScheme="linkedin"
                      fontSize={10}
                      className=""
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page + 1);
                      }}
                    >
                      {loading ? "Loading ..." : "Loadmore"}
                    </Button>
                  )}
                </div>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CategoryProduct;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import * as mod from "./../../url";
import TopHeader from "../../components/Layout/TopHeader";
import FooterArea from "../../components/Layout/FooterArea";
import {
  Box,
  Input,
  Button,
  useDisclosure,
  Center,
  FormLabel,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const Register = () => {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    otp: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateOTP = async () => {
    if (formData.phone.length !== 10) {
      return toast.error("Invalid phone number");
    }

    try {
      const res = await axios.post(`${mod.api_url}/api/v1/auth/generate-otp`, {
        phone: formData.phone,
        email: formData.email,
        mode: "new user", // Set your desired mode
      });
      toast.success("OTP sent on mobile & email");
    } catch (error) {
      console.error(error);
      toast.error("Error while sending OTP");
    }
  };

  const isValidForm = () => {
    const { name, email, password, phone, otp } = formData;
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const phoneRegex = /^([+]\d{2}[ ])?\d{10}$/;
    const otpRegex = /^([+]\d{2}[ ])?\d{6}$/;

    const newErrors = {};

    if (!name.match(nameRegex)) {
      newErrors.name = "Please enter a valid name.";
    }

    if (!email.match(emailRegex)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!password.match(passwordRegex)) {
      newErrors.password = "Wrong format of password.";
    }

    if (!phone.match(phoneRegex)) {
      newErrors.phone = "Please enter a valid phone number.";
    }
    if (!otp.match(otpRegex)) {
      newErrors.otp = "Please enter a valid otp number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    onClose();
    e.preventDefault();
    if (isValidForm()) {
      try {
        const res = await axios.post(
          `${mod.api_url}/api/v1/auth/register`,
          formData
        );
        if (res && res.data.success) {
          toast.success(res.data.message);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Fill the form correctly");
    }
  };

  const doubleClicked = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      !formData.phone
    ) {
      toast({
        title: "Fill all the Input",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    generateOTP();
    onOpen();
  };

  return (
    <>
      <TopHeader />
      <div className="register_page">
        <div className="register_wrapper">
          <div className="form">
            <h4 className="title">REGISTER FORM</h4>

            <div className="row" style={{ padding: "10px" }}>
              <div className="col-md-6">
                {" "}
                <FormLabel>Enter Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Your Name"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </div>
              <div className="col-md-6">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  label="Email"
                  variant="outlined"
                  placeholder="Enter Your email"
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </div>
            </div>
            <div className="row" style={{ padding: "10px" }}>
              <div className="col-md-6">
                <FormLabel>Enter Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  label="Password"
                  variant="outlined"
                  placeholder="Enter Your password"
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </div>
              <div className="col-md-6">
                <FormLabel>Enter Phone No</FormLabel>
                <Input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  label="Phone"
                  variant="outlined"
                  placeholder="Enter Your mobile"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </div>
            </div>

            <Center>
              <Button
                marginTop={3}
                width="50%"
                colorScheme="blue"
                onClick={doubleClicked}
              >
                Gerate OTP
              </Button>
            </Center>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign="center">Verify OTP</ModalHeader>
                <ModalBody>
                  <Input
                    type="text"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    label="OTP"
                    variant="outlined"
                    placeholder="OTP"
                    error={!!errors.otp}
                    helperText={errors.otp}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="green"
                    width="48%"
                    m={1}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button width="48%" colorScheme="red" onClick={onClose}>
                    Close Modal
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* </div> */}
            <hr />
            <div className="col-6 Register_Header">
              <NavLink to="/login" className="nav-link">
                <span>Existing customer</span>
                <span style={{ color: "blue", margin: 5 }}>Login Here</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <FooterArea />
    </>
  );
};

export default Register;

import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const CancellationsPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/Cancellations-policy.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <Heading>Subscription Cancellations Policy</Heading>
        <p>
          At ValuedProPvt, we strive to provide the best learning experience
          through our comprehensive LMS platform. We understand that
          circumstances may change, and you may need to cancel your subscription
          or course enrollment. This Cancellation Policy outlines the terms and
          conditions for cancellations and refunds. Please read this policy
          carefully before making any purchases or subscriptions on our website,
          valuedpropvt.com.
        </p>
        <h2>Subscription Cancellations</h2>
        <h5>Monthly Subscriptions</h5>
        <ul typeof="circle">
          <li>
            <strong>Cancellation:</strong> You can cancel your monthly
            subscription at any time. Your access to the LMS platform will
            remain active until the end of the current billing cycle.
          </li>
          <li>
            <strong>Refunds:</strong>We do not offer refunds for monthly
            subscriptions. Once a subscription is canceled, no further charges
            will be applied, but the current month’s payment is non-refundable.
          </li>
        </ul>
        <h5>Annual Subscriptions</h5>
        <ul typeof="circle">
          <li>
            <strong>Cancellation:</strong> You can cancel your annual
            subscription at any time. Your access to the LMS platform will
            remain active until the end of the current billing year.
          </li>
          <li>
            <strong>Refunds:</strong>If you cancel your annual subscription
            within 30 days of purchase, you are eligible for a full refund.
            After 30 days, no refunds will be issued for annual subscriptions.
          </li>
        </ul>
        <h3>Course Enrollment Cancellations</h3>
        <h5>Individual Courses</h5>
        <ul typeof="circle">
          <li>
            <strong>Cancellation:</strong> You can cancel your enrollment in
            individual courses within 6 days of purchase.
          </li>
          <li>
            <strong>Refunds:</strong> If you cancel your course enrollment
            within 6 days and have not accessed more than 10% of the course
            content, you are eligible for a full refund. No refunds will be
            issued if more than 10% of the course content has been accessed or
            if the cancellation is requested after 6 days of purchase.
          </li>
        </ul>
        <h5>Bundled Courses</h5>
        <ul typeof="circle">
          <li>
            <strong>Cancellation:</strong> You can cancel your enrollment in
            bundled courses within 6 days of purchase.
          </li>
          <li>
            <strong>Refunds:</strong> If you cancel within 6 days and have not
            accessed more than 10% of the total course content, you are eligible
            for a full refund. No refunds will be issued if more than 10% of the
            course content has been accessed or if the cancellation is requested
            after 6 days of purchase.
          </li>
        </ul>
        <h5>How to Cancel</h5>
        <p>
          To cancel your subscription or course enrollment, please follow these
          steps:
        </p>
        <ul typeof="1">
          <li>
            <strong>Log in to your account:</strong>Visit valuedpropvt.com and
            log in to your account.
          </li>
          <li>
            <strong>Navigate to Account Settings:</strong>Go to the "Account
            Settings" or "My Subscriptions" section.
          </li>
          <li>
            <strong>Select Cancel:</strong>Choose the subscription or course you
            wish to cancel and select the "Cancel" option.
          </li>
          <li>
            <strong>Confirmation:</strong> Follow the prompts to confirm your
            cancellation.
          </li>
        </ul>
        <p>
          Alternatively, you can contact our customer support team at
          support@valuedpropvt.com for assistance with cancellations.
        </p>
        <h4>Changes to This Cancellation Policy</h4>
        <p>
          We may update this Cancellation Policy from time to time to reflect
          changes in our practices or legal requirements. We will notify you of
          any significant changes by posting the updated policy on our website.
          Your continued use of our services after any changes indicates your
          acceptance of the updated Cancellation Policy.
        </p>
        <h5>Contact Us</h5>
        <p>
          If you have any questions or concerns about this Cancellation Policy,
          please contact us at:
        </p>
        <p>Email: support@valuedpropvt.com</p>
        <p>Contact No: 7827156839</p>
        <p>
          Address: Adress: H NO B 98 GROUND FLOOR,
          <br /> NEAR NANDA HOSPITAL CHATTARPUR EXT,
          <br /> Sanjay Colony Bhati Mines,
          <br /> South West Delhi, South West Delhi,
          <br /> Delhi, India, 110074
        </p>
        <p>
          Thank you for choosing ValuedProPvt. We are committed to providing you
          with a seamless and enriching learning experience.
        </p>
        <p>
          Thank you for choosing ValuedProPvt. We are committed to providing you
          with a seamless and enriching learning experience.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default CancellationsPolicy;

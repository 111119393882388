import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../styles/dashboard.css";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/cart";
import logo from "./../../img/logo.png";
import { FaUserGraduate } from "react-icons/fa";

const Mainmenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState("Right");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [cart] = useCart();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        <HiMiniBars3BottomLeft />
        <FaUserGraduate />
      </Button>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            {" "}
            <Link to="/" className="navbar-brand">
              <img src={logo} style={{ width: 100, height: 60 }} />
            </Link>
          </DrawerHeader>
          <DrawerBody>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Button marginTop={2} colorScheme="blue">
                <Link to="/">Home</Link>
              </Button>

              {!auth?.user ? (
                <>
                  <Button marginTop={2} colorScheme="blue">
                    <Link to="/login" className="nav-link navbarHeader">
                      Login
                    </Link>
                  </Button>
                </>
              ) : (
                <>
                  <Button marginTop={2} colorScheme="blue">
                    <Link
                      to={`/dashboard/${
                        auth?.user?.role === 1 ? "admin" : "user"
                      }`}
                      className="dropdown-item navbarHeader"
                    >
                      Dashboard
                    </Link>
                  </Button>
                  <Button marginTop={2} colorScheme="blue">
                    <NavLink onClick={handleLogout}>Logout</NavLink>
                  </Button>
                </>
              )}
            </ul>
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="red"
              width="100%"
              marginLeft={0}
              onClick={onClose}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Mainmenu;

import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";

const TermsansConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/TERM-&-CONDITION.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <Heading>Terms and Conditions</Heading>
        <p>
          Welcome to Valued Pro Solutions Private Limited ("we", "our", "us").
          These Terms and Conditions ("Terms") govern your use of our website
          and services, including online courses, educational content, and
          related resources provided through our platform (collectively referred
          to as the "Services").
        </p>
        <p>
          By accessing or using our Services, you agree to be bound by these
          Terms. If you do not agree to these Terms, please refrain from using
          our Services.
        </p>
        <h5>1. Eligibility</h5>
        <p>
          You must be at least 18 years old to access or use our Services. By
          using our Services, you represent and warrant that you meet this age
          requirement.
        </p>
        <h5>2. Account Registration</h5>
        <p>
          To access certain features of our Services, you may need to register
          for an account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate and complete.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account.
        </p>
        <h5>3. Use of Services</h5>
        <p>
          You agree to use our Services only for lawful purposes and in a manner
          consistent with these Terms and any applicable laws and regulations.
        </p>
        <p>
          You may not use our Services to distribute any harmful, illegal, or
          offensive content or to engage in any activities that could harm our
          reputation or interfere with the normal operation of our Services.
        </p>
        <h5>4. Course Enrollment and Access</h5>
        <p>
          Upon enrollment in a course, you are granted a limited, non-exclusive,
          non-transferable license to access and view the course content for
          which you have paid all required fees.
        </p>
        <p>
          Access to course content is provided for personal, non-commercial use
          only. You may not reproduce, distribute, modify, or create derivative
          works of any course content without our express written permission.
        </p>
        <h5>5. Payment and Refunds</h5>
        <p>
          All fees for courses and other Services must be paid in full before
          you can access the content. Prices are subject to change without
          notice.
        </p>
        <p>
          We may offer refunds under certain circumstances as outlined in our
          Refund Policy, which is incorporated into these Terms by reference.
        </p>
        <h5>6. Intellectual Property</h5>
        <p>
          All content provided through our Services, including text, graphics,
          logos, and course materials, is the intellectual property of Valued
          Pro Solutions Private Limited or our content providers. Unauthorized
          use of any content may violate copyright, trademark, and other laws.
        </p>
        <p>
          You may not use any of our trademarks, logos, or proprietary
          information without our express written consent.
        </p>
        <h5>7. User Conduct</h5>
        <p>
          You agree not to engage in any conduct that may disrupt or interfere
          with the operation of our Services, including hacking, data mining, or
          unauthorized access to our systems.
        </p>
        <p>
          You agree not to post or share any content that is defamatory,
          obscene, or otherwise harmful to others.
        </p>
        <h5>8. Termination</h5>
        <p>
          {" "}
          We reserve the right to suspend or terminate your access to our
          Services at any time, with or without notice, for any violation of
          these Terms or for any other reason at our discretion.
        </p>
        <h5>9. Disclaimer of Warranties</h5>
        <p>
          Our Services are provided "as is" without any warranties, express or
          implied. We do not guarantee that the Services will be uninterrupted,
          error-free, or free from viruses or other harmful components.
        </p>
        <h5>10. Limitation of Liability</h5>
        <p>
          To the fullest extent permitted by law, Valued Pro Solutions Private
          Limited shall not be liable for any indirect, incidental, special, or
          consequential damages arising from your use of our Services.
        </p>
        <h5>11. Governing Law</h5>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of , without regard to its conflict of laws principles.
        </p>
        <h5>12. Changes to These Terms</h5>
        <p>
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting the revised Terms on our
          website. Your continued use of our Services following the posting of
          changes constitutes your acceptance of those changes.
        </p>

        <p>Email: support@valuedpropvt.com</p>
        <p>Contact No: 7827156839</p>
        <p>
          Address: Adress: H NO B 98 GROUND FLOOR,
          <br /> NEAR NANDA HOSPITAL CHATTARPUR EXT,
          <br /> Sanjay Colony Bhati Mines,
          <br /> South West Delhi, South West Delhi,
          <br /> Delhi, India, 110074
        </p>
        <p>
          Thank you for choosing ValuedProPvt. We are committed to providing you
          with a seamless and enriching learning experience.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default TermsansConditions;

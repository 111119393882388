import React, { useEffect, useState } from "react";
import "../styles/PaymentPage.css";
import Layout from "./../components/Layout/Layout";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { Link, useNavigate } from "react-router-dom";
import DropIn from "braintree-web-drop-in-react";
import axios from "axios";
import toast from "react-hot-toast";
import * as mod from "./../../src/url.js";
import { Modal } from "react-bootstrap";

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Center,
  Divider,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

function PaymentPage() {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [clientToken, setClientToken] = useState("");
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [instance, setInstance] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const lessPrice = discount - amount;
  const delevrychage = 50;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const calculateTotalAmount = () => {
    const totalAmount = cart.reduce((total, item) => {
      const prices = (item.price * item.offers) / 100;
      const finalPrice = item.price - prices;
      return total + finalPrice * item.quantity;
    }, 0);
    return totalAmount;
  };
  const calculateOldAmount = () => {
    const totalAmount = cart.reduce((total, item) => {
      const prices = item.price;
      return total + prices * item.quantity;
    }, 0);
    return totalAmount;
  };

  const handleDropInReady = (dropInInstance) => {
    console.log("Drop-in instance is ready:", dropInInstance);
    setInstance(dropInInstance);
  };

  const onErrorDropInInitialization = (error) => {
    console.error("Error during Drop-in initialization:", error);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAmount(calculateTotalAmount());
    setDiscount(calculateOldAmount());
  }, [cart]);

  const getToken = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/braintree/token`
      );
      setClientToken(data?.clientToken);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getToken();
  }, [auth?.token]);

  const handlePayment = async () => {
    try {
      setLoading(true);

      if (selectedPaymentMethod === "online") {
        const { nonce } = await instance.requestPaymentMethod();
        const orderData = {
          paymentMethod: "online",
          products: cart.map((item) => item._id),
          orderItems: cart.map((item) => ({
            name: item.name,
            image: item.image || "",
            product: item._id,
            pdf: item.pdf,
            price: item.price,
            quantity: item.quantity,
          })),
          itemsPrice: amount,
          shippingPrice: amount > 499 ? 0 : delevrychage,
          taxPrice: 0,
          totalPrice: amount > 499 ? amount : amount + delevrychage,
          payment: {
            nonce,
          },
          buyer: {
            _id: auth.user?._id,
            // name: auth.user.name,
            // email: auth.user.email,
          },
        };
        const { data } = await axios.post(
          `${mod.api_url}/api/v1/product/braintree/payment`,
          {
            nonce,
            orderData,
          }
        );
        toast.success("Payment Completed Successfully");
      }
      setLoading(false);
      localStorage.removeItem("cart");
      setCart([]);
      navigate("/dashboard/user/orders");
    } catch (error) {
      console.log(error, "error in handle payment");
      setLoading(false);
      toast.error("something is wrong ");
    }
  };

  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1.1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };

  return (
    <Layout>
      {auth?.token ? (
        <>
          <div>
            <Text textAlign="center" fontSize="large">
              Checkout Page
            </Text>
            <Center>
              <Card
                maxW="sm"
                options={defaultOptions}
                style={{ height: "auto", width: "100%" }}
              >
                <CardBody>
                  <Image
                    src="/images/online-payments.jpg"
                    borderRadius="lg"
                    width="100%"
                  />
                  <Stack mt="6" spacing="3"></Stack>
                </CardBody>
                <Divider />
                <CardFooter>
                  <Button
                    width="100%"
                    colorScheme="linkedin"
                    fontSize={10}
                    className="text-center"
                    onClick={() => {
                      setSelectedPaymentMethod("online");
                      setShowPaymentModal(true);
                    }}
                  >
                    Pay Now
                  </Button>
                </CardFooter>
              </Card>
            </Center>

            <Modal
              show={showPaymentModal}
              onHide={() => setShowPaymentModal(false)}
            >
              <hr />
              {selectedPaymentMethod === "online" && (
                <>
                  <DropIn
                    options={{
                      authorization: clientToken,
                      paypal: {
                        flow: "vault",
                      },
                    }}
                    onInstance={handleDropInReady}
                    onError={onErrorDropInInitialization}
                  />
                  <ButtonGroup gap="1" padding={1}>
                    <Button
                      width="50%"
                      colorScheme="red"
                      fontSize={10}
                      onClick={() => setShowPaymentModal(false)}
                    >
                      Cancel Your Payment
                    </Button>{" "}
                    <Button
                      width="50%"
                      colorScheme="green"
                      onClick={handlePayment}
                      disabled={
                        !clientToken || loading || !instance || amount <= 0
                      }
                    >
                      {loading ? "Processing ...." : "Make Payment"}
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </Modal>
          </div>
        </>
      ) : (
        <>
          <div style={{ margin: "10%", marginLeft: "40%", marginBottom: 50 }}>
            <Text fontSize="xx-large" fontStyle="normal">
              {" "}
              Plese Login for Checkout
            </Text>
            <br />
            <Link to="/login">
              {" "}
              <Button width="60%" colorScheme="red">
                Login
              </Button>
            </Link>
          </div>
        </>
      )}
    </Layout>
  );
}

export default PaymentPage;

import React, { useEffect } from "react";
import "./../styles/Contact.css";
import Layout from "./../components/Layout/Layout";
import { BiMailSend, BiPhoneCall, BiSupport } from "react-icons/bi";
import FooterArea from "../components/Layout/FooterArea";
import TopHeader from "../components/Layout/TopHeader";
import { Box } from "@mui/material";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/contact-us.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <p>
          At Valued Pro Solutions Private Limited, we are dedicated to providing
          you with the best possible service and support. Whether you’re a
          prospective student, an existing customer, or simply interested in
          learning more about what we offer, our team is here to assist you
          every step of the way. We believe that communication is key to
          building lasting relationships with our customers, and we welcome your
          inquiries, feedback, and suggestions.
        </p>
        <h1>How to Reach Us</h1>
        <p>
          We offer multiple ways for you to get in touch with us. Choose the
          method that best suits your needs:
        </p>
        <h3>1. Email Support</h3>
        <p>
          Email is one of the most convenient ways to reach us, and we strive to
          respond to all emails promptly. Whether you have a question about our
          courses, need assistance with your account, or want to provide
          feedback, our support team is ready to help.
        </p>
        <p>
          General Inquiries: For general questions or information about our
          offerings, please email us at info@valuedpropvt.com.
        </p>
        <p>
          Customer Support: If you’re experiencing issues with our website,
          courses, or need technical support, contact our customer support team
          at info@valuedpropvt.com.
        </p>
        <p>
          Business Inquiries: For partnerships, collaborations, or
          business-related inquiries, please email us at info@valuedpropvt.com .
        </p>

        <h3>2. Phone Support</h3>
        <p>
          Sometimes, speaking to a real person is the quickest way to get the
          help you need. Our customer service representatives are available
          during business hours to assist you with any questions or concerns.
        </p>
        <p>Maill Us : info@valuedpropvt.com</p>
        <p>Business Hours:</p>
        <p>Monday to Friday: 9:00 AM - 6:00 PM</p>
        <p>Saturday: 10:00 AM - 2:00 PM</p>
        <p>Sunday: Closed</p>
        <p>
          When you call us, please have your account details handy so that we
          can assist you more efficiently.
        </p>
        <h3>3. Physical Address</h3>
        <p>
          If you prefer face-to-face communication or need to visit us for any
          reason, you’re welcome to drop by our office. Our team is always happy
          to meet with you in person.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "../styles/CartStyles.css";
import * as mod from "./../../src/url.js";
import {
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

const CartPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [clientToken, setClientToken] = useState("");
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const lessPrice = discount - amount;
  const isMobile = window.innerWidth < 768;
  const delevrychage = 50;

  // Replace 'userId' with the actual user ID you want to fetch

  const calculateTotalAmount = () => {
    const totalAmount = cart.reduce((total, item) => {
      const prices = (item.price * item.offers) / 100;
      const finalPrice = item.price - prices;
      return total + finalPrice * item.quantity;
    }, 0);
    return totalAmount;
  };
  const calculateOldAmount = () => {
    const totalAmount = cart.reduce((total, item) => {
      const prices = item.price;
      // const finalPrice = item.price-prices;
      return total + prices * item.quantity;
    }, 0);
    return totalAmount;
  };

  //add cart item same product
  const addToCart = (product) => {
    const existingProduct = cart.find((item) => item._id === product._id);
    if (existingProduct) {
      if (existingProduct.quantity < 20) {
        // If the product already exists, update the quantity
        const updatedCartItems = cart.map((item) =>
          item._id === product._id
            ? { ...item, quantity: existingProduct.quantity + 1 }
            : item
        );
        const itemsPrice = calculateTotalAmount();
        const oldPrice = calculateOldAmount();

        setAmount(itemsPrice);
        setDiscount(oldPrice);
        setCart(updatedCartItems);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
        toast.success("One Item added");
      } else {
        toast.error(
          "!sorry you can not add more than 5 quantities of one product"
        );
      }
    } else {
      // If the product doesn't exist, add it to the cart with quantity = 1
      setCart([...cart, { ...product, quantity: 1 }]);
      localStorage.setItem(
        "cart",
        JSON.stringify([...cart, { ...product, quantity: 1 }])
      );
      toast.success("product added to cart");
    }
  };
  // eslint-disable-next-line
  useEffect(() => {
    setAmount(calculateTotalAmount());
    setDiscount(calculateOldAmount());
    // eslint-disable-next-line
  }, [calculateTotalAmount(), calculateOldAmount()]);

  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      localStorage.setItem("cart", JSON.stringify(myCart));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const getToken = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/braintree/token`
      );
      setClientToken(data?.clientToken);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getToken();
  }, [auth?.token]);

  const handlePlaceOrder = () => {
    navigate("/cart/payments");
  };

  const handleAddToCart = () => {
    navigate("/");
  };

  return (
    <>
      <Layout>
        <h1 className="text-center bg-light ">
          {!auth?.user
            ? "Hello Guest"
            : `Hello  ${auth?.token && auth?.user?.name}`}
          <p className="text-center">
            {cart?.length
              ? `You Have ${cart.length} items in your cart ${
                  auth?.token ? "" : "please login to checkout !"
                }`
              : " Your Cart Is Empty"}
          </p>
        </h1>
        {cart?.length ? (
          <div className="row">
            <div className="col-md-6">
              {cart?.map((item) => (
                <Card
                  key={item._id}
                  maxW="100%"
                  onClick={() => {
                    navigate(`/product/${item.slug}/`);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                  marginBottom="10px"
                >
                  <CardBody>
                    <Flex direction="row" align="center">
                      <Image
                        src={item.image}
                        alt={item.name}
                        objectFit="cover"
                        width="100px"
                        height="100px"
                      />
                      <Stack spacing="1" flex="1" align="stretch">
                        <Heading paddingLeft="10px" size="md">
                          {item.name}
                        </Heading>
                        <Text
                          paddingLeft="10px"
                          color="blue.600"
                          fontSize="2xl"
                        >
                          ₹ {item.price}
                        </Text>
                      </Stack>
                      <Button
                        colorScheme="red"
                        width="70px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeCartItem(item._id);
                        }}
                      >
                        Remove
                      </Button>
                    </Flex>
                  </CardBody>
                </Card>
              ))}
            </div>

            <div className="col-md-6">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Quantity</Th>
                      <Th>Discount</Th>
                      <Th>Final Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        ({cart?.length} Item{cart.length > 1 ? "s" : ""})
                      </Td>
                      <Td>
                        {lessPrice.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </Td>
                      <Td>{amount}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Button
                width="100%"
                colorScheme="linkedin"
                fontSize={10}
                className="text-cent"
                style={{ marginTop: "10px", marginBottom: "35px" }}
                onClick={handlePlaceOrder}
              >
                Place Order{" "}
                {loading && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    width={18}
                    height={18}
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17.93c-3.95.49-7.4-2.91-7.91-6.86C4.61 9.78 7.49 6.61 11 6.08V8c-2.21.45-4 2.5-4 4.92 0 2.42 1.79 4.47 4 4.92v2.01c-1.17-.14-2.29-.46-3.32-.97L8.16 17c1.06.42 2.23.69 3.44.76V20h2v-2.24c1.24-.07 2.42-.34 3.5-.77l-.52-1.04c-1.02.51-2.14.83-3.31.97v-2.01c2.21-.45 4-2.5 4-4.92 0-2.42-1.79-4.47-4-4.92V6.08c3.51.53 6.39 3.7 6.91 7.73.51 3.95-2.96 7.36-6.91 7.12V20h-2v-2.24z" />
                  </svg>
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h4>Your Cart is Empty</h4>
            <Button
              width="30%"
              colorScheme="linkedin"
              marginBottom={3}
              fontSize={10}
              onClick={handleAddToCart}
            >
              Shop Now
            </Button>
            <br />
            <a
              href="https://giphy.com/embed/8PA8Ew3nw97yg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <iframe
                src="https://giphy.com/embed/8PA8Ew3nw97yg"
                width="100%"
                height="auto"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
                title="Empty Cart"
              ></iframe>
            </a>
          </div>
        )}
      </Layout>
    </>
  );
};
export default CartPage;

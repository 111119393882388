import React, { useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import "./../styles/Policy.css";
import TopHeader from "../components/Layout/TopHeader";
import FooterArea from "../components/Layout/FooterArea";
import { Box } from "@chakra-ui/react";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopHeader />
      <div className="col-md-12 ">
        <img
          src="/images/PRIVACY-POLICY.jpg"
          alt="contactus"
          style={{ width: "100%" }}
        />
      </div>
      <Box padding="30px">
        <p>
          Welcome to Valued Pro Solutions Private Limited ("we," "our," "us").
          We are committed to protecting your privacy and ensuring that your
          personal information is handled in a safe and responsible manner. This
          Privacy Policy explains how we collect, use, and protect your
          information when you use our website and services.
        </p>
        <h1>Information We Collect</h1>
        <p>
          Personal Information: When you register for an account, purchase a
          course, or contact us, we may collect personal information such as
          your name, email address, phone number, billing information, and other
          relevant details.
        </p>
        <p>
          Usage Data: We may collect information on how our website is accessed
          and used, including IP address, browser type, pages visited, and time
          spent on our site.
        </p>
        <ul typeof="circle">
          <li>Name</li>
          <li>Email address</li>
          <li>Contact information</li>
          <li>Payment information (if applicable)</li>
        </ul>
        <h3>Usage Data</h3>
        <p>
          We collect information about how you interact with our website and LMS
          platform, including:
        </p>
        <p>
          Cookies: We use cookies and similar tracking technologies to track the
          activity on our website and store certain information. You can set
          your browser to refuse cookies or indicate when a cookie is being
          sent.
        </p>
        <ul typeof="circle">
          <li>IP address</li>
          <li>Browser type</li>
          <li>Device information</li>
          <li>Pages visited</li>
          <li>Time spent on pages</li>
          <li>Referring URL</li>
        </ul>
        <h3>3. How We Use Your Information</h3>
        <p>We use your personal information to:</p>
        <p>
          Provide and maintain our services.
          <br /> Process transactions and send you confirmations and invoices.
        </p>
        <p>
          Improve our website, products, and services. Send you promotional
          information, updates, and newsletters (you can opt out at any time).
        </p>
        <p>Respond to your inquiries and provide customer support.</p>
        <p>Detect and prevent fraud and abuse.</p>
        <h3>4. Sharing Your Information</h3>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties except in the following circumstances:
        </p>

        <p>
          Service Providers: We may share your information with trusted
          third-party service providers who assist us in operating our website,
          conducting our business, or servicing you. These providers have access
          to your information only to perform these tasks on our behalf and are
          obligated not to disclose or use it for other purposes.
        </p>
        <p>
          Legal Requirements: We may disclose your personal information if
          required by law or in response to valid requests by public
          authorities.
        </p>

        <h3>5. Security of Your Information</h3>
        <p>
          We take the security of your personal information seriously and
          implement a variety of security measures to maintain the safety of
          your personal data. However, no method of transmission over the
          Internet or electronic storage is 100% secure. We strive to use
          commercially acceptable means to protect your information, but we
          cannot guarantee its absolute security.
        </p>
        <h3>Your Rights</h3>
        <p>You have the right to:</p>
        <p>
          Access and obtain a copy of your personal data. Rectify any
          inaccuracies in your personal data. Request the deletion of your
          personal data under certain circumstances.
        </p>
        <p>Object to or restrict the processing of your personal data.</p>

        <p>
          To exercise any of these rights, please maill us at
          info@valuedpropvt.com.
        </p>
        <h3>7. Children's Privacy</h3>
        <p>
          Our services are not intended for individuals under the age of 18. We
          do not knowingly collect personally identifiable information from
          children. If you are a parent or guardian and believe that your child
          has provided us with personal information, please contact us, and we
          will take steps to remove such information.
        </p>
        <h5>8. Changes to This Privacy Policy</h5>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. Changes
          are effective immediately upon posting.
        </p>
      </Box>
      <FooterArea />
    </>
  );
};

export default Policy;

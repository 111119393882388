import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../styles/dashboard.css";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Button,
  Wrap,
} from "@chakra-ui/react";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";

const UserMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState("left");

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        <HiMiniBars3BottomLeft />
      </Button>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            Manage Your Account
          </DrawerHeader>
          <DrawerBody>
            <Link to="/dashboard/user">
              <Button width="100%" marginBottom={3} colorScheme="gray">
                Dashboard
              </Button>
            </Link>
            <Link to="/dashboard/user/profile">
              <Button width="100%" marginBottom={3} colorScheme="gray">
                Profile
              </Button>
            </Link>
            <Link to="/dashboard/user/orders">
              <Button width="100%" marginBottom={3} colorScheme="gray">
                Orders
              </Button>
            </Link>
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="red"
              width="100%"
              marginLeft={0}
              onClick={onClose}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UserMenu;
